<template>
    <div>
        <div v-html="payGateWay"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            payGateWay: ''
        }
    },
     mounted () {
        this.payGateWay = this.$route.query.htmlData
        this.$nextTick(()=> {
            document.forms[0].submit()
        })
    }
}
</script>